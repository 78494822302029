import { Container, Fab, useMediaQuery } from '@mui/material';
import useSearchReviews from '../../hooks/useSearchReviews';
import { useSearchParamsStore } from '../../state/app';
import { useCallback, useRef, useState, FunctionComponent } from 'react';
import ReviewItem from './ReviewItem';
import { ENV } from '../../constants/common';
import ScrollTop from '../ScrollTop';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSearchParams } from 'react-router-dom';

const AdBox: FunctionComponent<{ layoutKey: string; adSlot: string }> = ({ layoutKey, adSlot }) =>
  ENV === 'prod' ? (
    <Container>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1220408494390549"
        crossOrigin="anonymous"
      ></script>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-format="fluid"
        data-ad-layout-key={layoutKey}
        data-ad-client="ca-pub-1220408494390549"
        data-ad-slot={adSlot}
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </Container>
  ) : null;

const ReviewList = () => {
  const [page, setPage] = useState<number>(1);
  const isXl = useMediaQuery('(min-width:1536px)');
  const isMd = useMediaQuery('(min-width:900px)');

  const adParams: {
    xl: { layoutKey: string; adSlot: string };
    md: { layoutKey: string; adSlot: string };
    xs: { layoutKey: string; adSlot: string };
  } = {
    xl: { layoutKey: '-ge+33+ba-an-hi', adSlot: '1115292697' },
    md: { layoutKey: '-gz-j+o-6f+gd', adSlot: '1081549398' },
    xs: { layoutKey: '-gk-t+19-9i+lb', adSlot: '1605042008' },
  };

  const { searchParams: search } = useSearchParamsStore(({ searchParams }) => ({ searchParams }));
  const { reviews, error, isLoading, hasMore } = useSearchReviews(search, page);

  const lastReviewElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const observer = useRef<IntersectionObserver | null>(null);

  return (
    <>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginTop: { xs: '10px', sm: null },
        }}
      >
        {reviews.map((review, i) => [
          <ReviewItem
            key={`review-${i}`}
            refProp={reviews.length === i + 1 ? lastReviewElementRef : null}
            review={review}
          />,
          i > 5 && i % 10 === 0 ? (
            <AdBox
              key={`inlinead-${i}`}
              {...(isXl ? adParams.xl : isMd ? adParams.md : adParams.xs)}
            />
          ) : null,
        ])}
        <div>{isLoading && !error && <ReviewItem skelaton />}</div>
        <div>
          {error && (
            <>
              <ReviewItem skelaton isError={!!error} />
            </>
          )}
        </div>
      </Container>
      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default ReviewList;
