import { Collapse, Toolbar, Grid, TextField, Box } from '@mui/material';
import { CountrySelect, StateSelect, CitySelect } from '../form/locationDropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useSearchParamsStore } from '../../state/app';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const ReviewFilters: FunctionComponent = () => {
  const { t } = useTranslation();
  const [urlSearchParams] = useSearchParams();
  const { searchParams: search, setSearchParams } = useSearchParamsStore();
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);

  useEffect(() => {
    setSearchParams({
      ...search,
      landlordName: urlSearchParams.get('landlordName') || search.landlordName,
    });
  }, []);

  const countrySelect = async (newValue: string) => {
    setSearchParams({ country: newValue, landlordName: search.landlordName });
  };
  const stateSelect = (newValue: string) => {
    setSearchParams({
      country: search.country,
      state: newValue,
      landlordName: search.landlordName,
    });
  };

  const citySelect = (newValue: string) => {
    setSearchParams({
      country: search.country,
      state: search.state,
      city: newValue,
      landlordName: search.landlordName,
    });
  };

  const cityChange = debounce((newValue: string) => {
    setSearchParams({
      country: search.country,
      state: search.state,
      city: newValue,
      landlordName: search.landlordName,
    });
  }, 500);

  const postalChange = debounce(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setSearchParams({ ...search, postal: e.target.value }),
    500
  );

  const landlordChange = debounce(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setSearchParams({ ...search, landlordName: e.target.value }),
    500
  );

  return (
    <>
      <Collapse
        sx={{
          height: { sm: 'auto !important' },
          visibility: { sm: 'inherit' },
          paddingTop: '10px',
        }}
        in={filterBoxOpen}
      >
        <Toolbar key="reviews-filter-toolbar">
          <Grid container spacing="10px">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CountrySelect value={search.country} onChange={countrySelect} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StateSelect
                value={search.state}
                onChange={stateSelect}
                countryCode={search.country}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CitySelect
                onChange={cityChange}
                onSelect={citySelect}
                value={search.city}
                countryCode={search.country}
                stateCode={search.state}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                key={`Dropdown${search.country}${search.state}${search.city}-postal`}
                defaultValue={search.postal}
                onChange={postalChange}
                size="small"
                sx={{ width: '100%', minWidth: { sm: '200px' } }}
                label={t('reviewsPage.filters.postal.label')}
                disabled={!search.country}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <TextField
                key={`Dropdown${search.country}${search.state}${search.city}-landlord`}
                defaultValue={urlSearchParams.get('landlordName') || search.landlordName}
                onChange={landlordChange}
                size="small"
                sx={{ width: '100%', minWidth: { sm: '200px' } }}
                label={t('reviewsPage.filters.landlord.label')}
                disabled={!search.country || !search.state}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Collapse>
      <Box
        onClick={() => setFilterBoxOpen((prev) => !prev)}
        sx={{
          cursor: 'pointer',
          display: { xs: 'block', sm: 'none' },
          width: '100%',
          textAlign: 'center',
        }}
      >
        <FontAwesomeIcon icon={filterBoxOpen ? faAngleUp : faAngleDown} />{' '}
        {filterBoxOpen ? t('reviewsPage.filters.hide') : t('reviewsPage.filters.show')}{' '}
        {t('reviewsPage.filters.filters')}{' '}
        <FontAwesomeIcon icon={filterBoxOpen ? faAngleUp : faAngleDown} />
      </Box>
    </>
  );
};
export default ReviewFilters;
