import {
  faFaceAngry,
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
  faFaceLaughBeam,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Rating,
  Skeleton as BaseSkeleton,
  Typography,
  SkeletonProps,
  Box,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Review } from '../../providers/landlordspotlight/types';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../ErrorBoundary';
import ModalImage from 'react-modal-image';

const RATING_FACES = [
  { icon: faFaceAngry, color: '#D06060' },
  { icon: faFaceFrown, color: '#D18D60' },
  { icon: faFaceMeh, color: '#77867F' },
  { icon: faFaceSmile, color: '#2D6F73' },
  { icon: faFaceLaughBeam, color: '#38AC57' },
];

const Skeleton: FunctionComponent<SkeletonProps & { isError?: boolean }> = (props) => {
  const { isError, sx, ...rest } = props;

  return (
    <BaseSkeleton
      sx={{
        ...sx,
        ...(isError === true
          ? {
              '&:last-child': {
                backgroundColor: 'rgb(186 43 92 / 35%)',
              },
            }
          : {}),
      }}
      {...rest}
    />
  );
};

type ReviewBoxProps =
  | {
      review: Review;
      refProp: ((node: HTMLDivElement) => void) | null;
    }
  | { skelaton: boolean; isError?: boolean };

const ReviewBox: FunctionComponent<ReviewBoxProps> = (props) => {
  const { t } = useTranslation();

  let isSkelaton: boolean = false;
  let isError: boolean = false;

  let headline: string = '';
  let addressString: string | undefined;
  let body: string = '';
  let privacy: number;
  let repair: number;
  let respect: number;
  let stability: number;
  let landlordName: string = '';
  let avgScore: number;
  let images: { uriPath: string }[] = [];

  let refProp: ((node: HTMLDivElement) => void) | null = null;

  if ('skelaton' in props) {
    isSkelaton = props.skelaton;
    isError = props.isError || false;
    privacy = 0;
    repair = 0;
    respect = 0;
    stability = 0;
    avgScore = 0;
  } else {
    headline = props.review.headline;
    body = props.review.body;
    privacy = props.review.privacy;
    repair = props.review.repair;
    respect = props.review.respect;
    stability = props.review.stability;
    landlordName = props.review.landlord.name;
    avgScore = props.review.landlord.averageRating.avgScore;
    images = props.review.images;
    refProp = props.refProp;

    const { address, city, postal, state } = props.review;

    addressString = [address, city, state, postal].filter(Boolean).join(', ');
  }

  return (
    <Grid
      ref={refProp}
      container
      spacing={0}
      maxWidth={['100%', '80%']}
      mx={'auto'}
      borderRadius="5px"
      boxShadow="1px 1px 10px 1px #e2e2e2"
      padding="10px"
    >
      <Grid item xs={3} md={1} display={'flex'}>
        {isSkelaton ? (
          <Skeleton isError={isError} variant="circular" width={64} height={64} />
        ) : (
          <FontAwesomeIcon
            size="3x"
            icon={RATING_FACES[Math.round(avgScore - 1)].icon}
            style={{ color: RATING_FACES[Math.round(avgScore - 1)].color }}
          />
        )}
      </Grid>
      <Grid item display={isError ? 'flex' : undefined} xs={9} md={11}>
        {isSkelaton && isError ? (
          <Typography pl={'5px'} variant="h2" my="auto">
            {t('reviewsPage.reviewBox.errorFetchingHeader')}
          </Typography>
        ) : isSkelaton ? (
          <Skeleton isError={isError} sx={{ fontSize: '1.5rem', width: '73%' }} />
        ) : (
          <>
            <Typography variant="h2" my="auto">
              {landlordName}
            </Typography>{' '}
            <Typography>
              ({t('reviewsPage.reviewBox.landlordAverage')}: {avgScore})
            </Typography>
          </>
        )}
      </Grid>
      {isSkelaton ? (
        <Grid item xs={12} pl="30px" mb="3px" mt="15px">
          <Skeleton isError={isError} width={'30%'} sx={{ fontSize: '1.25rem' }} />
        </Grid>
      ) : headline ? (
        <Grid item xs={12} pl="30px" mb="3px" mt="15px">
          <Typography variant="h3">{headline}</Typography>
          {isSkelaton ? null : <hr style={{ width: '50%', marginLeft: 0 }} />}
        </Grid>
      ) : (
        <Grid item xs={12} height={'12px'} />
      )}

      {addressString && (
        <Grid item xs={12} pl={{ md: '30px' }} mb="3px">
          <Typography fontSize={'0.8rem'} variant="subtitle1">
            {addressString}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} md={3} lg={2} pl="30px" pr={'25px'}>
        {isSkelaton ? (
          <Skeleton
            isError={isError}
            sx={{ display: { xs: 'none', md: 'block' } }}
            variant="rectangular"
            width={'90%'}
            height={'90px'}
          />
        ) : (
          <Grid container>
            <Grid item xs={6} md={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography flexGrow={1}>{t('reviewsPage.reviewBox.labels.repair')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Rating size="small" readOnly value={repair} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={12}>
              <Grid item xs={12}>
                <Typography flexGrow={1}> {t('reviewsPage.reviewBox.labels.stability')}</Typography>{' '}
              </Grid>
              <Grid item xs={12}>
                <Rating size="small" readOnly value={stability} />
              </Grid>
            </Grid>
            <Grid item xs={6} md={12}>
              <Grid item xs={12}>
                <Typography flexGrow={1}>{t('reviewsPage.reviewBox.labels.privacy')}</Typography>{' '}
              </Grid>
              <Grid item xs={12}>
                <Rating size="small" readOnly value={privacy} />
              </Grid>
            </Grid>
            <Grid item xs={6} md={12}>
              <Grid item xs={12}>
                <Typography flexGrow={1}>{t('reviewsPage.reviewBox.labels.respect')}</Typography>{' '}
              </Grid>
              <Grid item xs={12}>
                <Rating size="small" readOnly value={respect} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        {isSkelaton ? (
          <Skeleton isError={isError} variant="rectangular" width={'70%'} height={'90px'} />
        ) : (
          <Box
            display={'flex'}
            flexDirection={'column'}
            paddingRight={{ md: '30px' }}
            textAlign={'justify'}
          >
            <Typography marginBottom={'15px'}>{body}</Typography>
            <Grid container spacing={2}>
              {images.map(({ uriPath }, idx) => {
                return (
                  <Grid item key={`revImg-${idx}`} xs={12} sm={4} display={'flex'}>
                    <Box
                      component="div"
                      sx={{
                        display: 'inline-flex',
                        borderRadius: 2,
                        overflow: 'hidden',
                        border: '1px solid #eaeaea',
                        margin: 'auto',
                        width: '100%',
                        height: 'fit-content',
                        padding: 0,
                        boxSizing: 'border-box',
                        cursor: 'default',

                        '& img.modal-img': {
                          display: 'block',
                          width: '100%',
                          height: 'fit-content',
                        },
                      }}
                    >
                      <ModalImage
                        small={`https://public.dev.landlordspotlight.com/${uriPath}`}
                        large={`https://public.dev.landlordspotlight.com/${uriPath}`}
                        className="modal-img"
                        showRotate
                        hideDownload
                        width={'100%'}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default (props: ReviewBoxProps) => (
  <ErrorBoundary fallback={<ReviewBox skelaton />}>
    <ReviewBox {...props} />
  </ErrorBoundary>
);
