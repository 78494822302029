import { Container } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import ReactGA from 'react-ga4';
import ReviewList from '../components/reviews/ReviewList';
import ReviewFilters from '../components/reviews/Filters';
import { useModalStateStore } from '../state/modals';
import { removeUtms } from '../utils/common';

const Reviews: FunctionComponent<{ newReview?: boolean }> = ({ newReview }) => {
  const { openSubmitReviewModal } = useModalStateStore(({ openSubmitReviewModal }) => ({
    openSubmitReviewModal,
  }));

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/reviews',
      title: 'Reviews',
      hitCallback: removeUtms,
    });
  }, []);

  useEffect(() => {
    const title: string = document.title;
    document.title = 'Landlord Spotlight - Reviews';
    return () => {
      document.title = title;
    };
  }, []);

  useEffect(() => {
    if (newReview) openSubmitReviewModal();
  }, []);

  return (
    <>
      <Container sx={{ padding: { xs: '5px', sm: '15px' } }}>
        <ReviewFilters />
        <ReviewList />
      </Container>
    </>
  );
};

export default Reviews;
